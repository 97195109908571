<template>
  <div>
    <el-input
      v-model="params.keyword"
      style="width: 30%; margin: 10px 0"
      placeholder="请输入关键字"
    ></el-input>
    <el-empty description="暂无数据" v-if="!datas.length"></el-empty>
    <div class="topLine" v-for="(item, index) in datas" :key="item.id">
      <div class="flex1">
        <span class="s1">题解 | #{{ ++index }}#</span>
        <span class="s2">发表于 {{ item.update_time }}</span>
      </div>
      <div class="cont" v-html="item.data_content"></div>
    </div>
  </div>
</template>
<script>
import * as http from "@/api/order.js";
export default {
  data() {
    return {
      params: {
        keyword: "",
        page: 1,
        list_rows: 30,
        question_detail_id: "",
      },
      datas: [],
    };
  },
  watch: {
    "params.keyword": {
      handler(v) {
        this.getList();
        console.log(v);
      },
    },
  },
  mounted() {
    this.params.question_detail_id = this.$route.query.question_id;
    this.getList();
  },
  methods: {
    getList() {
      http.question_detail_tj({ ...this.params }).then((res) => {
        this.datas = res.data;
        this.$emit("getTjTotal", res.total);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.topLine {
  padding: 8px 0;
  box-sizing: border-box;
  border-top: 1px solid #f3f3f6;
  border-bottom: 1px solid #f3f3f6;
  .flex1 {
    display: flex;
    justify-content: space-between;
    .s1 {
      color: #333;
      font-size: 14px;
      font-weight: 700;
    }
    .s2 {
      color: #c3c3c6;
      font-size: 12px;
    }
  }
  .cont {
    color: #555;
    font-size: 12px;
    margin-top: 10px;
  }
}
</style>
